export const RP_TESTIMONIAL = [
    {
        testimonial: "The best explanations I've seen, works through each question step by step so you can really understand where you mistakes are and how to do it. thank you!",
        image: 'https://ui-avatars.com/api/?name=Kees&rounded=true&background=FEB525&color=fff',
        author: 'Kees',
        role: 'Student'
    },
    {
        testimonial: 'The explanations great! I enjoy doing the quizzes, really tests my knowledge. I like how it shows the working out to help me understand it. Great product',
        image: 'https://ui-avatars.com/api/?name=Shannon&rounded=true&background=219653&color=fff',
        author: 'Shannon',
        role: 'Student'
    },
    {
        testimonial: 'We thought the A guarantee was too good to be true, but the app really delivered, especially through the pandemic where we were worried out children would get left behind',
        image: 'https://ui-avatars.com/api/?name=Zak&rounded=true&background=E5345A&color=fff',
        author: 'Zak',
        role: 'Parent'
    },
    {
        testimonial: 'I found this after talking to one of the other Mums. I spoke directly to one of the founders by booking a demo and was quite impressed - very well thought out program of study - I loved it. My daughter really enjoys doing it and I can see her becoming better with maths - I wish I had this in school',
        image: 'https://ui-avatars.com/api/?name=Anita&rounded=true&background=219653&color=fff',
        author: 'Anita',
        role: 'Parent'
    },

]

export const TESTIMONIAL_DATA = [
    {
        testimonial: 'Easy to understand layout, very easy on the eyes, straightforward and simple. Overall I am quite satisfied with my experience!',
        image: 'https://ui-avatars.com/api/?name=Dylan&rounded=true&background=e5345a&color=fff',
        author: 'Dylan',
        role: 'Student'
    },
    {
        testimonial: 'The explanations great! I enjoy doing the quizzes, really tests my knowledge. I like how it shows the working out to help me understand it. Great product',
        image: 'https://ui-avatars.com/api/?name=Shannon&rounded=true&background=219653&color=fff',
        author: 'Shannon',
        role: 'Student'
    },
    {
        testimonial: "The best explanations I've seen, works through each question step by step so you can really understand where you mistakes are and how to do it. thank you!",
        image: 'https://ui-avatars.com/api/?name=Kees&rounded=true&background=FEB525&color=fff',
        author: 'Kees',
        role: 'Student'
    },
    {
        testimonial: 'My daughter felt QuickSense massively improved her learning of maths. It was pleasing to see her confidence grow as she worked her way through the program. Would recommend it to anyone!',
        image: 'https://ui-avatars.com/api/?name=Isla&rounded=true&background=94039C&color=fff',
        author: 'Isla',
        role: 'Parent'
    },
    {
        testimonial: 'We thought the A guarantee was too good to be true, but the app really delivered, especially through the pandemic where we were worried out children would get left behind',
        image: 'https://ui-avatars.com/api/?name=Zak&rounded=true&background=E5345A&color=fff',
        author: 'Zak',
        role: 'Parent'
    },
    {
        testimonial: 'I found this after talking to one of the other Mums. I spoke directly to one of the founders by booking a demo and was quite impressed - very well thought out program of study - I loved it. My daughter really enjoys doing it and I can see her becoming better with maths - I wish I had this in school',
        image: 'https://ui-avatars.com/api/?name=Anita&rounded=true&background=219653&color=fff',
        author: 'Anita',
        role: 'Parent'
    }
]

export const PARENT_TESTIMONIALS = [
    {
        testimonial: 'My daughter felt QuickSense massively improved her learning of maths. It was pleasing to see her confidence grow as she worked her way through the program. Would recommend it to anyone!',
        image: 'https://ui-avatars.com/api/?name=Isla&rounded=true&background=94039C&color=fff',
        author: 'Isla',
        role: 'Parent'
    },
    {
        testimonial: 'We thought the A guarantee was too good to be true, but the app really delivered, especially through the pandemic where we were worried out children would get left behind',
        image: 'https://ui-avatars.com/api/?name=Zak&rounded=true&background=E5345A&color=fff',
        author: 'Zak',
        role: 'Parent'
    },
    {
        testimonial: 'I found this after talking to one of the other Mums. I spoke directly to one of the founders by booking a demo and was quite impressed - very well thought out program of study - I loved it. My daughter really enjoys doing it and I can see her becoming better with maths - I wish I had this in school',
        image: 'https://ui-avatars.com/api/?name=Anita&rounded=true&background=219653&color=fff',
        author: 'Anita',
        role: 'Parent'
    },
    {
        testimonial: 'Very good program that gets great results. Would recommend. It complemments private tutoring well, allows practice and feedback when the teacher or tutor are not there',
        image: 'https://ui-avatars.com/api/?name=Anita&rounded=true&background=FEB525&color=fff',
        author: 'Hannah',
        role: 'Parent'
    }
]