import React from 'react'

const testimonialRpItem = ({image, testimonial, author, role, index}) => (
    <div className={`col-12 col-md-6 mt-md-0 ${index === 1 ? 'mt-3' : ''}`} key={index}>
        {
            image && <div className='d-flex align-items-center my-3'> 
                <img className={'lazyload'} loading={'lazy'} height={40} width={40} data-src={image} alt={`${author} avatar`}/>
                <p className='ml-3 h5 mt-2'>{author} – {role}</p>
            </div>
        }
        <p className='text-left'>{`"${testimonial}"`}</p>
        {!image && <h3 className='h6 mt-3'>{author}</h3>}
    </div>
)

export default testimonialRpItem