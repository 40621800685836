import React from 'react'

// Bootstrap Components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import SEO from '../components/SEO/seo'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'
import GetStarted from '../components/GetStarted/getStarted'
import Testimonials from '../components/Testimonials/testimonials'
import Button from '../components/Button/button'

//utils
import { RP_TESTIMONIAL, TESTIMONIAL_DATA } from '../utils/content/rpTestimonials'

const STAR_SVG = <svg width='24' height='24' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M24.913 5.18603L30.391 17.474L43.769 18.887C44.63 18.978 44.976 20.045 44.333 20.624L34.34 29.629L37.131 42.79C37.311 43.637 36.403 44.296 35.653 43.864L24 37.141L12.347 43.863C11.597 44.295 10.69 43.636 10.869 42.789L13.66 29.628L3.66705 20.623C3.02405 20.044 3.37105 18.977 4.23105 18.886L17.609 17.473L23.087 5.18503C23.439 4.39503 24.561 4.39503 24.913 5.18603Z' fill='url(#paint0_linear)'/>
    <defs>
        <linearGradient id='paint0_linear' x1='9.00905' y1='6.36003' x2='38.092' y2='45.266' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#FEB525'/>
            <stop offset='1' stopColor='#FEB705'/>
        </linearGradient>
    </defs>
</svg>

class IndexPage extends React.Component {
    __renderHeroSection() { 
        return <div>
            <div className={'d-flex align-items-center heroBg'} data-sal='fade' data-sal-duration='800' data-sal-delay='1200' data-sal-easing='ease-out-quad'>
                <Container className={'heroContainer'}>
                    <Row>
                        <Col className={'heroSection col-12 col-lg-6'} as='section'>
                            <h1 data-sal='slide-up' data-sal-duration='2000' data-sal-delay='300' data-sal-easing='ease-out-quart' className={'heroTitle'}>Get a guaranteed A in IGCSE, GCSE and <br className='d-none d-lg-block'/> O-level maths</h1>
                            <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart' className={'heroDescription'}>Answer 2,000+ questions with instant feedback and step by step explanations and videos</p>
                            <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart' className={'heroDescription'}>A – A* guaranteed or your money back</p>
                            <div className='d-flex flex-row flex-column-md align-items-center justify-content-center align-items-sm-start justify-content-sm-start' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                                {/* WRAPPER FOR ANIMATION */}
                                <Button classes={'px-5 mt-3'} primary>Try for free</Button>
                                <Button secondary isInternal classes={'d-none d-sm-inline-block ml-3 mt-3'} event={'website_learn_more_button_clicked'} eventValues={{location: 'Hero Section', page: 'Home'}} to={'how-it-works'} content={'Learn how it works'} />                              
                            </div>
                            <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart' className={'heroDescription pt-3 text-small d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center '}>
                                No credit card required&nbsp;<span><span className='d-none d-md-inline'>•</span>  &nbsp;<span style={{marginTop: '-4px'}}>{STAR_SVG}</span> 4.81/5 stars (161 reviews)</span></p>
                            <p className='text-small mt-4'>Are you a parent? <Button to={'/parents'} classes={'py-0 mt-0 mb-1 pl-0 --underline'} link><strong>Learn more here</strong></Button></p>

                        </Col>
                        <Col className={'col-12 col-lg-6'} as='figure'>
                            <video
                                className='w-100 rounded shadow' 
                                loading={'lazy'}
                                preload={'metadata'}
                                autoPlay={true}
                                loop={true}
                                muted={true}
                                poster='https://res.cloudinary.com/dtkzddu1x/image/upload/v1589268077/QuickSense/Landing_Page_Video_Poster_Quiz2.png'
                                playsinline={true}>
                                <source src='https://res.cloudinary.com/dtkzddu1x/video/upload/ac_none,c_scale,q_auto:best,vc_h264,w_720/v1589279746/QuickSense/Landing_Page_Quiz_Score.mp4' type='video/mp4'/>
                                <source src='https://res.cloudinary.com/dtkzddu1x/video/upload/ac_none,c_scale,q_auto:best,vc_h265,w_720/v1589279746/QuickSense/Landing_Page_Quiz_Score.mp4' type='video/mp4'/>
                            </video>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    __renderActivelyAnswer () {
        return <Section background='bg-light-blue'>

            <Col lg={6} className='col-lg-6 d-flex flex-column justify-content-center'>
                <h2 className='h1' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='500' data-sal-easing='ease-out-quart'>Actively answer thousands of questions</h2>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                  Passively watching videos or reading the textbook is a giant waste of time - you forget 75% of things you watch or read after <strong>one day</strong>.             
                </p>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                Learn actively and remember more with QuickSense. Answer questions and get instant feedback.           
                </p>   
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                We have over 2,000 questions (<em>with more added every week</em>) which cover every part of the syllabus. Make the time spent studying actually productive.          
                </p>     
                <div className='d-flex flex-sm-row flex-column justify-content-sm-start justify-content-centre align-items-center'
                    data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                    {/* WRAPPER FOR ANIMATION */}
                    <Button primary classes={'mb-0'} eventValues={{location: 'Value Prop 2 – Actively answer thousands of questions', page: 'Home'}}>Try for free</Button>
                    {/* MOBILE BUTTON*/}
                    <Button isInternal link content={'Learn how it works'} event={'website_learn_more_button_clicked'} to={'how-it-works'} classes={'--underline d-block d-sm-none'} eventValues={{location: 'Value Prop 2 – Actively answer thousands of questions', page: 'Home'}}/>
                    {/* DESKTOP BUTTON*/}                    
                    <Button isInternal secondary content={'Learn how it works'} event={'website_learn_more_button_clicked'} to={'how-it-works'} classes={'--underline d-none d-sm-inline-block ml-3'} eventValues={{location: 'Value Prop 2 – Actively answer thousands of questions', page: 'Home'}}/>
                </div>
            </Col>

            <Col as='figure' lg={6} className='d-flex flex-column justify-content-start align-items-start align-items-sm-center mt-3 mt-sm-4 mt-lg-0'
                data-sal='slide-left' data-sal-duration='2000' data-sal-delay='300' data-sal-easing='ease-out-quart'>
                <video
                    className='w-100 rounded shadow' 
                    loading={'lazy'}
                    preload={'metadata'}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    poster='https://res.cloudinary.com/dtkzddu1x/image/upload/v1589266901/QuickSense/Landing_Page_Video_Poster_Quiz1.png'
                    playsinline={true}>
                    <source src='https://res.cloudinary.com/dtkzddu1x/video/upload/ac_none,c_scale,q_auto:best,vc_h264,w_720/v1589267722/QuickSense/Landing_Page_Video_Quiz1.mp4' type='video/mp4'/>
                    <source src='https://res.cloudinary.com/dtkzddu1x/video/upload/ac_none,c_scale,q_auto:best,vc_h265,w_720/v1589267722/QuickSense/Landing_Page_Video_Quiz1.mp4' type='video/mp4'/>
                </video>
            </Col>
        </Section>
    }

    __renderInstantFeedback () {
        return <Section background='bg-light-green' rowClasses={'d-flex flex-column-reverse flex-lg-row'}>
            <Col as='figure' lg={6} className='col-lg-6' data-sal='slide-left' data-sal-duration='2000' data-sal-delay='300' data-sal-easing='ease-out-quart'>
                <picture>
                    <source data-srcset='https://res.cloudinary.com/quicksense/image/upload/v1587033198/1_eqlplx.webp' type='image/webp'/>
                    <img width='560' height='420' className={'lazyload  w-100 fit-contain'} loading='lazy' data-src='https://res.cloudinary.com/quicksense/image/upload/v1587033198/1_eqlplx.png' alt='Explanation detailing how to calculate speed' />
                </picture>
                <noscript>
                    <img src='https://res.cloudinary.com/quicksense/image/upload/v1587033198/1_eqlplx.png' className={'fit-contain w-100'} loading='lazy' width='560' height='420' alt='Explanation detailing how to calculate speed' />
                </noscript>
            </Col>

            <Col lg={6} className='col-lg-6'>
                <h2 className='h1' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='500' data-sal-easing='ease-out-quart'>Instant feedback and step by step explanations</h2>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='700' data-sal-easing='ease-out-quart'>
                    {'The best way to learn is to test yourself and learn from your mistakes. Every single one of our 2000+ questions has a step by step explanation that walks you through what to do, and how to do it.'}
                </p>
                <p>
                    Understand your gaps and know how to fix it.
                    <br/>
                    <br/>
                    <ul className='text-left'>
                        <li>Specific advice on the best way to answer exam questions</li>
                        <li>Highlighting of common mistakes people make</li>
                        <li>Tips on what the examiners are looking for</li>
                    </ul>
                </p>
                <div data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                    {/* WRAPPER FOR ANIMATION */}
                    <Button primary classes={'mb-0 mb-sm-4'} eventValues={{location: 'Value Prop 3 – Instant Feedback & Step by step explanations', page: 'Home'}}>Try for free</Button>
                    {/* MOBILE BUTTON*/}
                    <Button to={'how-it-works'} event={'website_learn_more_button_clicked'} classes={'--underline d-block d-sm-none my-3'} content={'Learn how it works'} link isInternal eventValues={{location: 'Value Prop 3 – Instant Feedback & Step by step explanations', page: 'Home'}}/>
                    {/* DESKTOP BUTTON*/}
                    <Button to={'how-it-works'} event={'website_learn_more_button_clicked'} classes={'d-none d-sm-inline-block ml-3 my-3'} content={'Learn how it works'} secondary isInternal eventValues={{location: 'Value Prop 3 – Instant Feedback & Step by step explanations', page: 'Home'}}/>
                </div>
            </Col>
        </Section>
    }

    __renderSaveHours () {
        return <Section background='bg-light-yellow'>
            <Col lg={6} className='col-lg-6'>
                <h2 className='h1' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='500' data-sal-easing='ease-out-quart'>Save hours of study time by focusing on the things you don’t know</h2>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>Don’t waste hours revising things you already know – focus on what you need to improve on.</p>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>QuickSense learns your weaknesses and then gives you more questions until you master them.</p>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>Increase your confidence by tracking how well {'you\'ll'} do in the exam – giving you enough time to master what you {'don\'t'} know. </p>
                <div data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                    {/* WRAPPER FOR ANIMATION */}
                    <Button primary eventValues={{location: 'Value Prop 4 – Save horus of study time'}}>Try for free</Button>
                    {/* MOBILE BUTTON*/}
                    <Button link isInternal content={'Learn how it works'} classes={'--underline d-block d-sm-none'} to={'how-it-works'} event={'website_learn_more_button_clicked'} eventValues={{location: 'Value Prop 4 – Save horus of study time'}}/>
                    {/* DESKTOP BUTTON*/}
                    <Button secondary isInternal content={'Learn how it works'} classes={'--underline d-none d-sm-inline-block ml-3'} to={'how-it-works'} event={'website_learn_more_button_clicked'} eventValues={{location: 'Value Prop 4 – Save horus of study time'}}/>
                </div>
            </Col>
            <Col lg={6} as='figure' className='mt-sm-3 mt-3 mt-md-5 col-lg-6' data-sal='slide-left' data-sal-duration='2000' data-sal-delay='300' data-sal-easing='ease-out-quart'>
                <picture>
                    <source data-srcset='https://res.cloudinary.com/quicksense/image/upload/v1587033182/vp-3_a5ilxx.webp' type='image/webp'/>
                    <img data-src='https://res.cloudinary.com/quicksense/image/upload/v1587033182/vp-3_a5ilxx.png' className={'sustainabilityImage lazyload'} loading='lazy'  width='540' height='660' alt='QuickSense Dashboard showing strengths & weaknesses' />
                </picture>
                <noscript>
                    <img src='https://res.cloudinary.com/quicksense/image/upload/v1587033182/vp-3_a5ilxx.png' className={'sustainabilityImage'} loading='lazy' width='540' height='660' alt='QuickSense Dashboard showing strengths & weaknesses' />
                </noscript>
            </Col>
        </Section>
    }

    __renderPrivateTutor () {
        return <Section background='bg-light-blue'>
            <Col lg={6} className='col-lg-6 d-flex flex-column justify-content-center align-items-center'>
                <h2 className='h1' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='500' data-sal-easing='ease-out-quart'>More effective and cheaper than a private tutor – guaranteed</h2>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>Private tutors are expensive, costing 1,000s of dollars a year for – all of one hour of extra teaching per week.</p>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>Written by top teachers and educators with 20+ years of experience, QuickSense provides a personalised learning pathway to a guaranteed A – all for the cost of a few hours of tutoring.</p>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>As an option you can add 24/7 access these tutors to answer any questions.</p>
                <div className='w-100' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                    {/* WRAPPER FOR ANIMATION */}
                    <Button primary classes={'mb-0 mb-sm-4'} eventValues={{location: 'Value Prop 5 – More effective than a private tutor', page: 'Home'}}>Try for free</Button>
                    {/* MOBILE BUTTON*/}
                    <Button link isInternal classes={'--underline d-block d-sm-none my-3'} to={'faq'} event={'website_faq_button_clicked'} content={'Read FAQ'} eventValues={{location: 'Value Prop 5 – More effective than a private tutor', page: 'Home'}}/>
                    {/* DESKTOP BUTTON*/}
                    <Button secondary isInternal classes={'d-none d-sm-inline-block ml-3 my-3'} to={'faq'} event={'website_faq_button_clicked'} content={'Read FAQ'} eventValues={{location: 'Value Prop 5 – More effective than a private tutor', page: 'Home'}}/>
                </div>
            </Col>
            <Col lg={6} className='col-lg-6'>
                <picture>
                    <source data-srcset='https://res.cloudinary.com/dtkzddu1x/image/upload/v1628507307/QuickSense/clip-1764.webp' type='image/webp'/>
                    <img data-src='https://res.cloudinary.com/dtkzddu1x/image/upload/v1628507307/QuickSense/clip-1764.svg' className={'sustainabilityImage lazyload'} loading='lazy'  width='540' height='660' alt='Illustration of parent saving money  in piggy bank with QuickSense' />
                </picture>
                <noscript>
                    <img src='https://res.cloudinary.com/dtkzddu1x/image/upload/v1628507307/QuickSense/clip-1764.svg' className={'sustainabilityImage'} loading='lazy' width='540' height='660' alt='Illustration of parent saving money  in piggy bank with QuickSense' />
                </noscript>
            </Col>
        </Section>
    }

    __renderCambridge () {
        return <Section background='bg-light-pink'>
            <Col lg={12} className='col-lg-12 text-center'>
                <h2 className='h1 website__vp-title'>Specifically made for Cambridge IGCSE, GCSE and O-Levels Maths</h2>
                <p>All our content is specifically written for Cambridge IGCSE, GCSE and O-Levels Maths.</p>
                <p>
                We {'don\'t'} recycle content from other exam boards. 
                    <br/>
                Learn exactly everything you need to know.
                </p>
            </Col>
            <Col lg={12} className='col-lg-12 text-center'>
                <img className='mt-3 lazyload w-100' width='372' height='313' alt='Meeba Mascot' data-src='https://res.cloudinary.com/quicksense/image/upload/v1587026221/cambridge_crrg5c.svg'/>
                <noscript>
                    <img className='mt-3 lazy' alt='Meeba Mascot' data-src='https://res.cloudinary.com/quicksense/image/upload/v1587026221/cambridge_crrg5c.svg'/>
                </noscript>
            </Col>
        </Section>
    }

    __renderParentTestimonials () {
        return <Section spacing='py-3' background='bg-light-yellow'>
            <Col lg={12}>
                <h2 className='h1 text-center mt-0 mt-sm-4' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='300' data-sal-easing='ease-out-quart'>Testimonials</h2>
                <Row className='border rounded py-3 px-3 bg-white'>
                    {
                        RP_TESTIMONIAL.map((data,index) => (
                            <Testimonials {...data} key={index} />
                        ))
                    }
                </Row>
            </Col>
        </Section>
    }

    __renderTestimonials () {
        return <Section spacing='py-3' background='bg-light-yellow'>
            <Col lg={12}>
                <h2 className='h1 text-center mt-0 mt-sm-4' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='300' data-sal-easing='ease-out-quart'>Testimonials</h2>
                <Row className='border rounded py-3 px-3 bg-white d-none d-md-flex'>
                    {
                        TESTIMONIAL_DATA.map((data,index) => (
                            <Testimonials {...data} key={index} />
                        ))
                    }
                </Row>
                <Row className='border rounded py-3 px-3 bg-white d-flex d-md-none'>
                    {
                        RP_TESTIMONIAL.map((data,index) => (
                            <Testimonials {...data} key={index} />
                        ))
                    }
                </Row>
            </Col>
        </Section>      
    }

    render () {
        return (
            <>
                <SEO title='Home'/>
                <Layout type='floating' classes='fw-landing-page text-center text-sm-left'>
                    {this.__renderHeroSection()}
                    {this.__renderTestimonials()}
                    {this.__renderActivelyAnswer()}
                    {this.__renderInstantFeedback()}
                    {this.__renderSaveHours()}
                    {this.__renderPrivateTutor()}
                    {this.__renderCambridge()}
                    {this.__renderParentTestimonials()}
                    <GetStarted 
                        background={'bg-light-blue'} 
                        secondaryButton={true} />
                </Layout>
            </>
        )
    }
}

export default IndexPage
